import { $ } from '../utils/dom'

const portfolioCarousel = new Swiper('.header-portfolio__carousel', {
    loop: true,
})

const portfolioCarouselContent = new Swiper('.header-portfolio__carousel-content', {
    loop: true,
    autoplay: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
})

portfolioCarousel.controller.control = portfolioCarouselContent
portfolioCarouselContent.controller.control = portfolioCarousel
portfolioCarouselContent.autoplay.stop()

const menuButton = $('.bt-menu-trigger')
if(menuButton !== null) {
    menuButton.addEventListener('click', function(e) {
        if(!this.classList.contains('bt-menu-open')){
            portfolioCarouselContent.autoplay.stop()
        }else{
            portfolioCarouselContent.autoplay.start()
        }
    })
}