import { $ } from '../utils/dom'

document.addEventListener('DOMContentLoaded', function() {
    const $adminBar = document.getElementById('wpadminbar')

    const $sidebarSection = $('.post-sidebar')
    if($sidebarSection !== null){
        function setSidebarTop() {
            const $sidebarMenuButton = $('.menu-button')
    
            if ( window.innerWidth > 981 ) {
                let offset = 15
                offset += $sidebarMenuButton ? $sidebarMenuButton.offsetHeight : 0
                offset += $adminBar ? $adminBar.offsetHeight : 0
                $sidebarSection.style.setProperty('--top-position', `${offset}px`)
            } else {
                $sidebarSection.style.removeProperty('--top-position')
            }
        }
        window.addEventListener('scroll', setSidebarTop )
        window.addEventListener('resize', setSidebarTop )
        setSidebarTop()
    }
    
    const $sommaireSection = $('.post-sommaire')
    if($sommaireSection !== null){
        function setSommaireTop() {
            const $siteBranding = $( '.site-branding' )
            const $siteBrandingMini = $( '.site-branding-mini' )
    
            if ( window.innerWidth > 981 ) {
                let offset = 15
                offset += $siteBranding ? $siteBranding.offsetHeight : 0
                offset += $siteBrandingMini ? $siteBrandingMini.offsetHeight : 0
                offset += $adminBar ? $adminBar.offsetHeight : 0
                $sommaireSection.style.setProperty('--top-position', `${offset}px`)
            } else {
                $sommaireSection.style.removeProperty('--top-position')
            }
        }
        window.addEventListener('scroll', setSommaireTop )
        window.addEventListener('resize', setSommaireTop )
        setSommaireTop()
    }


    const $header = $('.header--actu');
    const $thumbSection = $('.post-thumbnail');
    if($header !== null) {
        function removeThumbnailIfEmpty() {
            if ( window.innerWidth < 980 ) {
                if($thumbSection.childNodes.length === 0){
                    $thumbSection.innerHTML = $header.innerHTML
                    $header.innerHTML = ''
                }
            } else {
                if($header.childNodes.length === 0){
                    $header.innerHTML = $thumbSection.innerHTML
                    $thumbSection.innerHTML = ''
                }
            }
        }
        window.addEventListener('resize', removeThumbnailIfEmpty )
        removeThumbnailIfEmpty()
    }
})