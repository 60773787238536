import { $, $$, offsetTop } from "../utils/dom"

const windowWidth = window.innerWidth
const windowHeight = window.innerHeight
const isTouch = $('html').classList.contains('touch')

if(windowWidth > 768) {
    if(!isTouch) {
        $$('.parallax').forEach(el => el.style.backgroundAttachment = 'fixed')
    }

    /* fix vertical when not overflow
     call fullscreenFix() if .fullscreen content changes */
    function fullscreenFix(){
        var h = $('body').offsetHeight;
        
        $$(".content-b").forEach(element => {
            if(element.offsetHeight > h){ 
                element.closest(".fullscreen").classList.add("overflow")
            }
        })
    }
    window.addEventListener('resize', fullscreenFix)
    fullscreenFix()
    
    /* resize background images */
    function backgroundResize(){
        $$(".background").forEach(element => {
            let parallaxElement = element.classList.contains('parallax'),
                containerWidth = element.offsetWidth,
                containerHeight = element.offsetHeight,
                img = new Image()

            img.addEventListener('load', function() {
                var imageWidth = img.width,
                    imageHeight = img.height,
                    ratio = imageWidth / imageHeight,
                    diff = 100,
                    remainingHeight = 0
                    
                if(parallaxElement && !isTouch){
                    remainingHeight = windowHeight - containerHeight
                }

                // set img values depending on cont
                imageHeight = containerHeight + remainingHeight + diff
                imageWidth = imageHeight * ratio

                // fix when too large
                if(containerWidth > imageWidth){
                    imageWidth = containerWidth
                    imageHeight = imageWidth / ratio
                }

                element.setAttribute("data-resized-imagewidth", imageWidth)
                element.setAttribute("data-resized-imageheight", imageHeight)
            })
            img.src = element.style.backgroundImage.replace("url(", "").replace(")", "").replace("\"", "").replace("\"", "")
        });
    }
    window.addEventListener('resize', backgroundResize)
    window.addEventListener('focus', backgroundResize)
    backgroundResize()

    /* set parallax background-position */
    function parallaxPosition(){
        let windowTop = window.scrollY,
            windowBottom = windowTop + windowHeight,
            currentWindow = (windowTop + windowBottom) / 2

        $$(".parallax").forEach(element => {
            let backgroundPositionY,
                horizontalPosition,
                overflowHeight,
                elementHeight = element.offsetHeight,
                elementTop = offsetTop(element),
                elementBottom = elementTop + elementHeight,
                imageHeight = element.getAttribute("data-resized-imageheight"),
                min = 0,
                max = - imageHeight + windowHeight

            // overflow changes parallax
            overflowHeight = elementHeight < windowHeight ? imageHeight - elementHeight : imageHeight - windowHeight; // fix height on overflow

            elementTop = elementTop - overflowHeight;
            elementBottom = elementBottom + overflowHeight;

            // value with linear interpolation
            backgroundPositionY = min + (max - min) * (currentWindow - elementTop) / (elementBottom - elementTop);

            // set background-position
            horizontalPosition = element.getAttribute("data-oriz-pos");
            horizontalPosition = horizontalPosition ? `${horizontalPosition}px` : "50%";
            element.style.backgroundPosition = `${horizontalPosition} ${backgroundPositionY}px`

        });

        $$(".parallax-horizontal").forEach(element => {
            let backgroundPositionX,
                horizontalPosition,
                overflowHeight,
                width = element.offsetWidth,
                elementTop = offsetTop(element),
                elementBottom = elementTop + width,
                imageWidth = element.getAttribute("data-resized-imagewidth"),
                min = 0,
                max = - imageWidth + windowWidth

            // overflow changes parallax
            overflowHeight = width < windowWidth ? imageWidth - width : imageWidth - windowWidth

            elementTop = elementTop - overflowHeight
            elementBottom = elementBottom + overflowHeight

            backgroundPositionX = min + (max - min) * (currentWindow - elementTop) / (elementBottom - elementTop)

            // set background-position
            horizontalPosition = element.getAttribute("data-oriz-pos")
            horizontalPosition = horizontalPosition ? `${horizontalPosition}px` : "50%"
            element.style.backgroundPosition = `${backgroundPositionX}px ${horizontalPosition}`
        });

    }

    if(!isTouch){
        window.addEventListener('resize', parallaxPosition)
        window.addEventListener('scroll', parallaxPosition)
        parallaxPosition()
    }
}
