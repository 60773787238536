import { scrollTo, slideDown, slideUp } from "../utils/animations";
import { $, $$, offsetTop } from "../utils/dom";

$$('.js-scrollTo').forEach(el => {
    el.addEventListener('click', e => {
        let anchor = e.target.getAttribute('href')
        scrollTo(document.getElementById(anchor))
    })
})

let posScroll,
    button = $('.bt-menu-trigger'),
    menu = document.getElementById('primary-volet'),
    menuVolet = document.getElementById('secondary-volet'),
    menucontainer = document.getElementById('site-navigation'),
    masthead = document.getElementById('masthead'),
    mapIframe = $('.map-iframe')

button.addEventListener('click', function (){
    button.classList.toggle('bt-menu-open')
    menu.classList.toggle('open')
    menuVolet.classList.toggle('open')
    menucontainer.classList.toggle('open')
    masthead.classList.toggle('open')
    if(mapIframe !== null && !mapIframe.classList.contains('activated')) {
        mapIframe.src = mapIframe.dataset.src
        mapIframe.classList.add('activated')
    }
})

if(typeof window.addEventListener === 'function') {
    window.addEventListener('scroll', e => {
        posScroll = window.scrollY
        let siteBranding = $('.site-branding')
        if(siteBranding !== null) {
            if(window.innerWidth < 768) {
                if(posScroll > 50){
                    siteBranding.classList.add('minify-logo')
                    siteBranding.classList.remove('full-logo')
                } else {
                    siteBranding.classList.remove('minify-logo')
                    siteBranding.classList.add('full-logo')
                }
            } else {
                if(posScroll > 350){
                    siteBranding.classList.add('minify-logo')
                    siteBranding.classList.remove('full-logo')
                } else {
                    siteBranding.classList.remove('minify-logo')
                    siteBranding.classList.add('full-logo')
                }
            }
        }
    
        const section2 = document.getElementById('section-2')
        if(section2 !== null){
            let footer_sec2 = offsetTop(section2) + section2.offsetHeight
            if (footer_sec2 <= posScroll + 800) {
                section2.classList.add('scroll-sec2-start')
            }else{
                section2.classList.remove('scroll-sec2-start')
            }
        }
    })
}

let charts_Opacity = $$(".opacity"),
    charts_Opacity_texte = $$(".opacity-texte"),
    charts_Opacity_texte_top = $$(".opacity-texte-S-top"),
    charts_Slide_Top_Opacity = $$(".slide-top-opacity"),
    charts_Slide_Bottom_Opacity = $$(".slide-bottom-opacity");

/**
 * Return boolean when an element is visible on screen
 * 
 * @param {HTMLElement|null} element 
 * @returns {boolean}
 */
function isVisible( element ) {
    if(element === null) {
        return false;
    }

    let viewPortHeight = window.innerHeight,
        scrollTop = window.scrollY,
        currElementPosY = offsetTop(element) + 200,
        elementHeight = element.offsetHeight;

    return ( currElementPosY + elementHeight > scrollTop && currElementPosY < ( viewPortHeight + scrollTop ) )
}

/**
 * Return boolean when an element is visible on screen
 * 
 * @param {HTMLElement|null} element 
 * @returns {boolean}
 */
function isVisibleTop ( element ) {
    if(element === null) {
        return false;
    }

    let viewPortHeight = window.innerHeight,
        scrollTop = window.scrollY,
        currElementPosY = offsetTop(element) + 600,
        elementHeight = element.offsetHeight;

    return ( currElementPosY + elementHeight > scrollTop && currElementPosY < ( viewPortHeight + scrollTop ) )
}

/**
 * @param {HTMLElement[]} charts 
 */
function animateOpacityWhenVisible(charts) {
    charts.forEach(element => {
        if ( isVisible( element )  ) {
            element.classList.add("animateOpacity")
        }
    })
}

/**
 * @param {HTMLElement[]} charts
 */
function animateOpacityTexteWhenVisible(charts) {
    charts.forEach(element => {
        if (isVisible(element)) {
            element.classList.add("animateOpacityTexte")
        }
    })
}

/**
 * @param {HTMLElement[]} charts
 */
function animateOpacityTexteTopWhenVisible(charts) {
    charts.forEach(element => {
        if ( isVisibleTop( element )  ) {
            element.classList.add("animateOpacityTexte")
        }
    })
}

/**
 * @param {HTMLElement[]} charts
 */
function animateSlideTopOpacityWhenVisible(charts) {
    charts.forEach(element => {
        if ( isVisibleTop( element )  ) {
            element.classList.add("animateSlideTopOpacity")
        }
    })
}

/**
 * @param {HTMLElement[]} charts
 */
function animateSlideBottomOpacityWhenVisible(charts) {
    charts.forEach(element => {
        if ( isVisibleTop( element )  ) {
            element.classList.add("animateSlideBottomOpacity")
        }
    })
}

/* FUNCTIONS END */
if(window.innerWidth > 768){

    if(typeof window.addEventListener === 'function') {
        window.addEventListener('scroll', e => {
            animateOpacityWhenVisible(charts_Opacity )
            animateOpacityTexteWhenVisible(charts_Opacity_texte )
            animateSlideTopOpacityWhenVisible(charts_Slide_Top_Opacity )
            animateOpacityTexteTopWhenVisible(charts_Opacity_texte_top)
            animateSlideBottomOpacityWhenVisible(charts_Slide_Bottom_Opacity )
        } )
    }

    /* On load */
    animateOpacityWhenVisible(charts_Opacity )
    animateOpacityTexteWhenVisible(charts_Opacity_texte )
    animateSlideTopOpacityWhenVisible(charts_Slide_Top_Opacity )
    animateOpacityTexteTopWhenVisible(charts_Opacity_texte_top)
    animateSlideBottomOpacityWhenVisible(charts_Slide_Bottom_Opacity )
}

const subMenus = $$('#primary-menu.menu > li.menu-item-has-children > a')
if(subMenus.length > 0) {
    subMenus.forEach(subMenu => {
        subMenu.addEventListener('click', function(e) {
            e.preventDefault()
            this.parentNode.childNodes.forEach(el => {
                if(typeof el.className !== "undefined" && el.className.indexOf('sub-menu') !== -1) {
                    if(el.className.indexOf('open') !== -1) {
                        slideUp(el)
                        el.className = el.className.replace(" open", '')
                    } else {
                        slideDown(el)
                        el.className += " open"
                    }
                }
            })
        })
    })
}
