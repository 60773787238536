import { $$ } from '../utils/dom'

const isMultiple = $$('.home-slider .swiper-slide').length > 1

const homeCarousel = new Swiper('.home-slider', {
    loop: isMultiple,
    autoplay: isMultiple,
    navigation: isMultiple ? {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    } : false,
})