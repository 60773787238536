
/**
 * @param {HTMLElement} element
 * @param {HTMLElement|null} parent
 */
export function offsetTop(element, parent = null) {
    let top = element.offsetTop;
    while ((element = element.offsetParent)) {
        if (parent === element) {
            return top;
        }
        top += element.offsetTop;
    }
    return top;
}

/**
 * @param {string} selector
 * @return {HTMLElement}
 */
export function $(selector) {
    return document.querySelector(selector);
}

/**
 * @param {string} selector
 * @return {HTMLElement[]}
 */
export function $$(selector) {
    return Array.from(document.querySelectorAll(selector));
}