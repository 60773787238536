/* Swiper Testimonials */
var swiper = new Swiper(".home-testimonials", {
    slidesPerView: 1,
    spaceBetween: 0,
    autoHeight: true,
    loop: false,
    grabCursor: true,
    speed: 800,
    effect: 'fade',
    /*autoplay: {
        delay: 3000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
    },*/
    pagination: {
        el: ".kl-testimonials-pagination",
        clickable: true,
      },
});