import './contact'
import './homepage-animations'
import './parallax'
import './script'
import './blog-featured-posts'
import './portfolio-lastest-posts'
import './typewriter-keywords'
import './home-slider'
import './portfolio-carousel'
import './dynamic-headings'
import './forms'
import './home-testimonials'