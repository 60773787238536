import { $$ } from "../utils/dom";

const isMultiple = $$('#portfolio-lastest-posts .swiper-slide').length > 1

const portfolioLastestCarousel = new Swiper('#portfolio-lastest-posts', {
    spaceBetween: 20,
    slidesPerView: 4,
    loop: isMultiple,
    autoplay: isMultiple,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        0: {
            slidesPerView: 2.25,
        },
        768: {
            slidesPerView: 3,
            navigation: false,
        },
        992: {
            slidesPerView: 4,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        }
    }
})