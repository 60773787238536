import { $, $$ } from "../utils/dom"

$$('.informations-item').forEach(element => {
    element.addEventListener('mouseenter', e => {
        e.preventDefault()

        e.target.querySelector('.content-informations').classList.remove('animated', 'faster', 'fadeInRight')
        e.target.querySelector('.title-informations').classList.remove('show')
        e.target.querySelector('.title-informations').classList.add('hide')

        setTimeout(function () {
            e.target.querySelector('.content-informations').classList.add('animated', 'faster', 'fadeInRight')
        }, 500)
    })

    element.addEventListener('mouseleave', e => {
        e.preventDefault()

        e.target.querySelector('.content-informations').classList.remove('animated', 'faster', 'fadeInRight')
        e.target.querySelector('.content-informations').classList.add('animated', 'faster', 'fadeOutUp')

        e.target.querySelector('.title-informations').classList.remove('show')
        e.target.querySelector('.title-informations').classList.add('hide')

        setTimeout(function () {
            e.target.querySelector('.title-informations').classList.remove('hide')
            e.target.querySelector('.title-informations').classList.add('show')
            e.target.querySelector('.content-informations').classList.remove('animated', 'faster', 'fadeOutUp')
        }, 500)
    })
})

const openChoices = $('[href="#open_choices"]')
if(openChoices !== null) {
    openChoices.addEventListener('click', e => {
        e.preventDefault()
        $$('.form-choice').forEach(el => el.classList.add('active'))
    })
}

const openPro = $('[href="#open_pro"]')
if(openPro !== null) {
    openPro.addEventListener('click', e => {
        e.preventDefault()
        let height = $('.form-pro').offsetHeight + 390;

        $('.kl_form').style.height = `${height}px`
        $('.form-pro').classList.add('active')
    })
}

const openSimple = $('[href="#open_simple"]')
if(openSimple !== null) {
    openSimple.addEventListener('click', e => {
        e.preventDefault()
        let height = $('.kl_form > .form-simple').offsetHeight + 390;

        $('.kl_form').style.height = `${height}px`
        $('.kl_form > .form-simple').classList.add('active')
    })
}

const changeFormItems = $$('[href="#change_form"]')
if(changeFormItems.length > 0) {
    changeFormItems.forEach(changeForm => {
        changeForm.addEventListener('click', e => {
            e.preventDefault()
    
            let formSelector = e.target.dataset.form,
                height = $(formSelector).offsetHeight + 390;
     
            $$('.form-pro.active, .form-simple.active').forEach(el => el.classList.remove('active'))
     
            $('.kl_form').style.height = `${height}px`
            $(formSelector).classList.add('active')
        })
    })
}

const close = $('[href="#close"]')
if(close !== null) {
    close.addEventListener('click', e => {
        e.preventDefault()
        
        e.target.parentNode.classList.remove('active')
    })
}

const closeFormItems = $$('[href="#close_form"]')
if(closeFormItems.length > 0) {
    closeFormItems.forEach(closeForm => {
        closeForm.addEventListener('click', e => {
            e.preventDefault()
     
            $('.kl_form').style.height = ''
            e.target.parentNode.classList.remove('active')
        })
    })
}